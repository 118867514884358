<template>
  <div class="rules-popup-wrapper">
    <CModal
      :show.sync="isShow"
      :backdrop="backdrop"
      :closeOnBackdrop="closeOnBackdrop"
      :centered="centered"
      :size="size"
      :color="color"
    >
      <template #header-wrapper>
        <div class="d-flex justify-content-center">
          <template>
            <!-- <strong>{{$t('rolesPermissions.roleName')}}</strong> -->
          </template>
        </div>
      </template>
      <div class="close" @click="buttonList[0].func">
        <img src="@imgPath/img/svg/icons/cross.svg" />
      </div>

      <span style="text-align: center">{{
        $t("rolesPermissions.roleName")
      }}</span>
      <ValidationObserver v-slot="{ handleSubmit }">
        <CForm @submit.prevent="handleSubmit(onConfirmCreateRole)">
          <cTextInputComponent
            v-model="roleNameObj.roleName"
            :placeholder="$t('rolesPermissions.addRolePlaceholder')"
            rules="required|max:20"
          />

          <div class="d-flex rules-switch-popup"></div>
          <div class="row d-flex justify-content-end mr-5px">
            <button
              type="submit"
              class="btn buttonspace action-c-btn btn-success removewrap btn-success removewrap"
            >
              <img src="@imgPath/img/svg/icons/correct.svg" />
              {{ $t("rolesPermissions.save") }}
            </button>
          </div>
        </CForm>
      </ValidationObserver>
      <template #footer-wrapper>
        <span></span>
      </template>
    </CModal>
  </div>
</template>

<script>
import { VALIDATE_SUCCESS_RES, getErrorMessage } from "@/util/apiValidate.js";
import { handleJWT, getErrorAlertValue } from "../../util/util";
import { ValidationObserver } from "vee-validate";

import BaseConfiguration from "../../config/base";
import { COMPONENT_NAME } from "../../util/constants";
import cTextInputComponent from "../form/cTextInputComponent";
import apiCreateRole from "../../services/apiCreateRole.js";
import Logger from "../../services/logger";
// import nb from "../../../src/translations/validation/nb.json";
// import en from "../../../src/translations/validation/en.json";
import { LOG_TYPE } from "../../config/API_DATA";

// localize({
//     en: {
//         messages: en.messages
//     },
//     nb: {
//         messages: nb.messages
//     }
// });
export default {
  name: COMPONENT_NAME.C_ADD_ROLE,
  components: {
    cTextInputComponent,
    ValidationObserver,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },

    backdrop: {
      type: Boolean,
      default: true,
    },

    closeOnBackdrop: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "lg",
    },

    color: {
      type: String,
      default: "dark",
    },

    heading: {
      type: String,
      default: "",
    },
    buttonList: {
      type: Array,
      //    default: []
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [
        {
          name: null,
          func: {
            type: Function,
          },
          color: {
            type: String,
            default: "primary",
          },
        },
      ],
    },
  },

  data: () => ({
    labelIcon: {
      labelOn: "\u2713",
      //  labelOff: '\u2715'
    },
    roleNameObj: {
      roleName: null,
    },
    isShow: false,
    alertValue: null,
  }),
  methods: {
    ///to create a role
    async onConfirmCreateRole() {
      try {
        let retVal = await this.createRoleRes(this.roleNameObj);
        if (retVal) {
          this.$emit("input", retVal);
          this.isShow = false;

          //   alert(retVal);
        }
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_ADD_ROLE,
          "error while creating a role",
          err.toString(),
          "onConfirmCreateRole",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    ///call api to create role
    async createRoleRes(pRoleNameObj) {
      let retValue;
      try {
        if (BaseConfiguration.isDebug) console.log(" createRoleRes");
        let roleReq = apiCreateRole.buildRequest(pRoleNameObj);
        if (roleReq) {
          let roleRes = await apiCreateRole.getResponse(roleReq);

          if (VALIDATE_SUCCESS_RES(roleRes)) {
            retValue = roleRes.data.data;
          } else {
            let error = getErrorMessage(roleRes);
            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.alertValue = getErrorAlertValue(error);
            }
          }
        }
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_ADD_ROLE,
          "error in getting response from GetAgentDetails API",
          err.toString(),
          "getAgentDetails",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return retValue;
    },
  },
  watch: {
    //   this.isShow=this.show;
  },
  created() {
    this.isShow = this.show;
  },
};
</script>
<style scoped>
.header-color {
  background: green;
}

.icon-color {
  color: white;
}
</style>
