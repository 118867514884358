<template>
  <div>
    <CCard>
      <CCardHeader class="grey-header">
        <CRow class="flex-flow-column">
          <CCol>
            <strong>{{ $t("rolesPermissions.title") }}</strong>
          </CCol>
          <p class="desc">{{ $t("rolesPermissions.desc") }}</p>
        </CRow>
      </CCardHeader>

      <CCardBody class="pt-3px">
        <CCardHeader class="pt-28px">
          <CRow class="mob-jc-flex-start ai-center jc-center">
            <div class="sub-title-rule">
              <strong>
                {{ $t("rolesPermissions.select") }}
              </strong>
            </div>
            <div class="add-role d-flex">
              <div class="role-wrapper mb--4px">
                <cDropDown
                  v-if="isRolesShowState"
                  :ctrlName="'roleName'"
                  :isShowCode="true"
                  :isMultiple="false"
                  :isSearchable="true"
                  :isSystemRole="true"
                  :isB2BRole="true"
                  :isHmsRole="true"
                  :url="getRolesServiceURL()"
                  v-on:input="onRoleChange"
                ></cDropDown>
              </div>
              <button
                type="submit"
                class="btn action-c-btn pl-20px btn-success add-icon-btn"
                @click="onAddRole"
              >
                <img
                  src="https://image.flaticon.com/icons/svg/1828/1828925.svg"
                  height="16px"
                  class="height-unset"
                />
              </button>
            </div>
            <!-- <img src="@imgPath/img/svg/icons/small_screen.svg" alt="" v-if="fullscreen" @click="toggle" class="fullscreen-btn m-auto mr-0imp ">
              <img src="@imgPath/img/svg/icons/full_screen.svg" alt="" v-if="!fullscreen" @click="toggle" class="fullscreen-btn m-auto mr-0imp "> -->
            <!-- <CButton @click="onAddRole">ADD</CButton> -->

            <cAddRoleModal
              v-if="isAddRole"
              :show="true"
              :heading="$t('flightResults.tcHeading')"
              v-on:input="onRecreateRoles"
              :buttonList="[
                {
                  name: $t('userStatus.cancel'),
                  func: onCancelRoleModal,
                  color: 'danger',
                },
                { name: $t('userStatus.ok'), color: 'success' },
              ]"
            ></cAddRoleModal>
          </CRow>
        </CCardHeader>

        <CRow>
          <CAlert v-if="alertValue" show color="danger">
            <div v-if="alertValue && alertValue.hasOwnProperty('valueList')">
              <p v-for="(value, index) in alertValue.valueList" :key="index">
                {{ value }}
              </p>
            </div>
            <div v-if="alertValue && alertValue.hasOwnProperty('codeList')">
              <span>{{ $t("cAlert.errorCode") + " : " }}</span
              ><span v-for="(code, index) in alertValue.codeList" :key="index">
                {{ code + " " }}
              </span>
            </div>
            <div v-if="alertValue && alertValue.constructor == String">
              <p>
                {{ alertValue }}
              </p>
            </div>
          </CAlert>
        </CRow>

        <cTable
          :items="lsModulesPermissions"
          :itemsPerPage="lsModulesPermissions.length"
          :fields="lsFields"
          hover
          id="rules-table"
          :loading="isLoading"
        >
          <template #title="{ item }">
            <td>
              <strong>{{ item.title }}</strong>

              <p class="table-value-desc">{{ item.description }}</p>
            </td>
          </template>

          <template #no-items-view>
            <div class="text-center my-5">
              <h2>{{ isLoading ? "" : $t("rolesPermissions.listEmpty") }}</h2>
            </div>
          </template>
          <template #R-header class="read">
            <div class="d-flex">
              {{ $t("rolesPermissions.R") }}
              <cHelpTip
                :content="$t('helpTip.rolesPermissionsRead')"
              ></cHelpTip>
            </div>
          </template>
          <template #R="{ item }" class="read">
            <td>
              <CSwitch
                v-if="
                  getPermissionStatus(item.availablepermission, 'R') != null
                "
                class="mx-1"
                color="success"
                :checked="getPermissionStatus(item.availablepermission, 'R')"
                @update:checked="onPermissionUpdate(item, 'R')"
                variant="3d"
                v-bind="labelIcon"
                type="checkbox"
              />
            </td>
          </template>

          <template #A-header class="read">
            <div class="d-flex">
              {{ $t("rolesPermissions.A") }}
              <cHelpTip
                :content="$t('helpTip.rolesPermissionsRead')"
              ></cHelpTip>
            </div>
          </template>
          <template #A="{ item }">
            <td>
              <CSwitch
                v-if="
                  getPermissionStatus(item.availablepermission, 'A') != null
                "
                class="mx-1"
                color="success"
                :checked="getPermissionStatus(item.availablepermission, 'A')"
                variant="3d"
                v-bind="labelIcon"
                @update:checked="onPermissionUpdate(item, 'A')"
                type="checkbox"
              />
            </td>
          </template>

          <template #W-header class="read">
            <div class="d-flex">
              {{ $t("rolesPermissions.W") }}
              <cHelpTip
                :content="$t('helpTip.rolesPermissionsWrite')"
              ></cHelpTip>
            </div>
          </template>
          <template #W="{ item }">
            <td>
              <CSwitch
                v-if="
                  getPermissionStatus(item.availablepermission, 'W') != null
                "
                class="mx-1"
                color="success"
                :checked="getPermissionStatus(item.availablepermission, 'W')"
                variant="3d"
                v-bind="labelIcon"
                @update:checked="onPermissionUpdate(item, 'W')"
                type="checkbox"
              />
            </td>
          </template>

          <template #D-header class="read">
            <div class="d-flex">
              {{ $t("rolesPermissions.D") }}
              <cHelpTip
                :content="$t('helpTip.rolesPermissionsDelete')"
              ></cHelpTip>
            </div>
          </template>
          <template #D="{ item }">
            <td>
              <CSwitch
                v-if="
                  getPermissionStatus(item.availablepermission, 'D') != null
                "
                class="mx-1"
                color="success"
                :checked="getPermissionStatus(item.availablepermission, 'D')"
                variant="3d"
                v-bind="labelIcon"
                @update:checked="onPermissionUpdate(item, 'D')"
                type="checkbox"
              />
            </td>
          </template>
        </cTable>
      </CCardBody>

      <CCardFooter
        v-if="lsModulesPermissions.length > 0"
        class="d-flex justify-content-end"
      >
        <CRow>
          <CCol>
            <CButton
              color="success"
              type="submit"
              class="action-c-btn pl-20px"
              @click="showModal()"
            >
              <img
                src="@imgPath/img/svg/icons/save.svg"
                height="16px"
                class="height-unset"
              />
              {{ $t("rolesPermissions.save") }}
            </CButton>

            <!-- <CButton @click="onPermissionsFormSave(lsModulesPermissions)" color="success"> 
                        {{$t('rolesPermissions.save')}}
            </CButton>-->
          </CCol>
        </CRow>
      </CCardFooter>
    </CCard>
    <!-- <cAlertModal
      :buttonList="showAlertModal?getModalBtnLs():null"
      :status="1"
      :show="showAlertModal"
      desc="Permissions updated successfully"
    ></cAlertModal> -->
    <cAlertModal
      v-if="showAlertModal"
      :buttonList="buttonList"
      :status="modalStatus"
      :key="modalStatus + 'i'"
      :show="showAlertModal"
      :desc="confirmText"
    ></cAlertModal>
  </div>
</template>

<script>
import cAddRoleModal from "./../../components/modal/cAddRoleModal";

import cDropDown from "./../../components/form/cDropDown";
import cAlertModal from "./../../components/form/cAlertModal";
import { updateDataEdited } from "@/router/index";
import apiUpdatePermissions from "./../../services/apiUpdatePermissions";
import apiRolesPermissions from "./../../services/apiRolesPermissions";
import Logger from "./../../services/logger";
import BaseConfiguration from "../../config/base";

import { VALIDATE_SUCCESS_RES, getErrorMessage } from "../../util/apiValidate";
import {
  handleJWT,
  getErrorAlertValue,
  addClassToBody,
  removeClassBody,
  cloneData,
} from "../../util/util";

import { mapGetters, mapActions } from "vuex";

import SERVICE_URL, { FILE_NAME, LOG_TYPE } from "./../../config/API_DATA";
import { COMPONENT_NAME, ALERT_MODAL_STATUS } from "./../../util/constants";
import cHelpTip from "./../../components/cHelpTip";
import cTable from "./../../components/dataTable/cTable";

export default {
  name: COMPONENT_NAME.ROLES_PERMISSIONS,

  components: {
    cDropDown,
    cAlertModal,
    cAddRoleModal,
    cHelpTip,
    cTable,
  },

  data: () => {
    return {
      isAddRole: false,
      isRolesShowState: true,
      showAlertModal: false,
      role: null,
      labelIcon: {
        labelOn: "\u2713",
        //  labelOff: '\u2715'
      },

      lsModulesPermissions: [],
      alertValue: null,
      lsFields: [],
      modalStatus: ALERT_MODAL_STATUS.INFO,
      buttonList: [],
      confirmText: null,
      isLoading: true,
      // fullscreen: false
    };
  },

  methods: {
    ...mapActions("sRolesPermissions", {
      CLEAR_ROLES_PERMISSIONS_STORE: "CLEAR_ROLES_PERMISSIONS_STORE",
      SET_ROLES_PERMISSIONS_REQ: "SET_ROLES_PERMISSIONS_REQ",
      SET_ROLES_PERMISSIONS_RES: "SET_ROLES_PERMISSIONS_RES",
      SET_UPDATE_PERMISSIONS_REQ: "SET_UPDATE_PERMISSIONS_REQ",
      SET_UPDATE_PERMISSIONS_RES: "SET_UPDATE_PERMISSIONS_RES",
    }),
    //        toggle () {
    //         this.$refs['fullscreen'].toggle()
    //         // recommended
    //         // this.fullscreen = !this.fullscreen // deprecated
    //       },
    //  fullscreenChange (fullscreen) {
    //         this.fullscreen = fullscreen
    //       },

    showModal(pStatus) {
      try {
        // let retValue;
        this.showAlertModal = true;
        if (pStatus) {
          this.modalStatus = pStatus;
        }
        let desc = null;
        if (this.modalStatus == ALERT_MODAL_STATUS.INFO) {
          this.buttonList = [
            {
              name: this.$t("rolesPermissions.no"),
              func: this.toggleAlertModal,
              color: "danger",
            },
            {
              name: this.$t("rolesPermissions.yes"),
              func: this.onPermissionsFormSave,
              color: "success",
            },
          ];
          desc = this.$t("rolesPermissions.confirmUpdate", {
            roleName: this.role.roleName,
          });
        } else if (this.modalStatus == ALERT_MODAL_STATUS.SUCCESS) {
          this.buttonList = [
            {
              name: this.$t("rolesPermissions.ok"),
              func: this.toggleAlertModal,
              color: "danger",
            },
          ];
          desc = this.$t("rolesPermissions.success");
        }
        this.confirmText = desc;
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.USER_STATUS,
          "error in showModal",
          err.toString(),
          "showModal",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      // return retValue;
    },

    //emit from cAddRole
    // eslint-disable-next-line no-unused-vars
    onRecreateRoles(pRoleObj) {
      try {
        this.isRolesShowState = false;
        this.isAddRole = false;
        let that = this;
        setTimeout(function () {
          that.isRolesShowState = true;
        });
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ROLES_PERMISSIONS,
          "error in onAddedRole",
          err.toString(),
          "onAddedRole",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    onCancelRoleModal() {
      try {
        if (BaseConfiguration.isDebug) console.log("onCancelRoleModal");
        this.isAddRole = false;
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ROLES_PERMISSIONS,
          "error while cancel popup modal",
          err.toString(),
          "onCancelRoleModal",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    ///to show cAddRole Modal
    onAddRole() {
      try {
        if (BaseConfiguration.isDebug) console.log("onAddRole");
        // if (this.isAddRole) {
        this.isAddRole = true;
        // }
        // let that = this;
        // setTimeout(function() {
        //   that.isAddRole = true;
        // });
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ROLES_PERMISSIONS,
          "error while showing popup modal",
          err.toString(),
          "onAddRole",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    getRolesServiceURL: () => {
      return SERVICE_URL.GET_USER_ROLES;
    },
    async updatePermissions(pListPermissions) {
      let returnValue;
      try {
        let updatePermissionsReq = apiUpdatePermissions.buildRequest(
          pListPermissions
        );

        if (updatePermissionsReq != null) {
          this.SET_UPDATE_PERMISSIONS_REQ(updatePermissionsReq);

          let updatePermissionsRes = await apiUpdatePermissions.getResponse(
            updatePermissionsReq
          );

          if (VALIDATE_SUCCESS_RES(updatePermissionsRes)) {
            returnValue = updatePermissionsRes;
            this.showAlert(null);
            this.SET_UPDATE_PERMISSIONS_RES(updatePermissionsRes);
          } else {
            let error = getErrorMessage(updatePermissionsRes);

            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.showAlert(getErrorAlertValue(error));
            }
          }
        } else {
          throw "invalid request";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ROLES_PERMISSIONS,
          "error in  Updating Permissions",
          err.toString(),
          "updatePermissions()",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }

      return returnValue;
    },

    toggleAlertModal() {
      this.modalStatus = ALERT_MODAL_STATUS.INFO;
      this.showAlertModal = !this.showAlertModal;
    },

    async onPermissionsFormSave() {
      this.showAlertModal = false;
      let pListPermissions = this.lsModulesPermissions;
      if (pListPermissions && pListPermissions.length > 0) {
        let updateRes = await this.updatePermissions(pListPermissions);

        if (updateRes) {
          // this.toggleAlertModal();
          this.showModal(ALERT_MODAL_STATUS.SUCCESS);

          await this.onRoleChange(this.role);
        }
        updateDataEdited(false);
      }
    },
    getModalBtnLs() {
      return [
        {
          name: this.$t("rolesPermissions.ok"),
          func: this.toggleAlertModal,
          color: "success",
        },
      ];
    },
    async onRoleChange(pRole) {
      try {
        if (BaseConfiguration.isDebug) console.log("onRoleChange");
        if (pRole) {
          this.role = pRole;
          let rolesPermissionsRes = await this.getRolesPermissionsRes(
            pRole.roleName
          );

          this.lsModulesPermissions = this.getModulesPermissions(
            rolesPermissionsRes
          );
          this.lsFields = this.getFields(rolesPermissionsRes);
        }
        if (this.isLoading) {
          this.isLoading = false;
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ROLES_PERMISSIONS,
          "error in on Role Change",
          err.toString(),
          "onRoleChange",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    getPermissionStatus(pAvailablePermission, pPermissionName) {
      let returnValue;
      try {
        if (pAvailablePermission && pPermissionName) {
          for (let permission of pAvailablePermission) {
            if (permission.name == pPermissionName) {
              returnValue = permission.isAllowed;
            }
          }
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ROLES_PERMISSIONS,
          "error in  get Roles Permission Status",
          err.toString(),
          " getPermissionStatus()",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }

      return returnValue;
    },

    onPermissionUpdate(pRoleObj, pPermissionName) {
      try {
        if (BaseConfiguration.isDebug) console.log("onPermissionUpdate");
        if (
          pRoleObj &&
          pRoleObj.module_code &&
          pRoleObj.availablepermission &&
          pPermissionName
        ) {
          for (let role of this.lsModulesPermissions) {
            if (role.module_code == pRoleObj.module_code) {
              //to check role id
              for (let permission of role.availablepermission) {
                if (permission.name == pPermissionName) {
                  permission.isAllowed = !permission.isAllowed;
                }
              }
            }
          }

          this.lsModulesPermissions = cloneData(this.lsModulesPermissions);
        } else {
          throw "invalid  role object";
        }
        updateDataEdited(true);
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ROLES_PERMISSIONS,
          "error in  on Permission Update",
          err.toString(),
          "onPermissionUpdate()",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    getFields(pRolesPermissionsRes) {
      let keys = [];
      let returnValue = [
        {
          key: "title",
          _style: "width:20%",
          // sorter: true,

          label: this.$t("rolesPermissions.func"),
        },
      ];
      try {
        if (pRolesPermissionsRes && pRolesPermissionsRes.data)
          for (let role of pRolesPermissionsRes.data) {
            for (let rights of role.availablepermission) {
              if (!keys.includes(rights.name)) {
                keys.push(rights.name);

                returnValue.push({
                  key: rights.name,
                  _style: "width:10%",
                  label: this.$t("rolesPermissions." + rights.name),
                  // sorter: false
                });
              }
            }
          }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ROLES_PERMISSIONS,
          "error in  getting Table Fields",
          err.toString(),
          "getFields()",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }

      return returnValue;
    },

    getModulesPermissions(pRolesPermissionsRes) {
      let returnValue;
      if (
        pRolesPermissionsRes &&
        pRolesPermissionsRes.data &&
        pRolesPermissionsRes.data.length
      ) {
        // returnValue = OrderByList(pRolesPermissionsRes.data,"title","asc");
        returnValue = pRolesPermissionsRes.data;
      } else {
        returnValue = [];
      }

      return returnValue;
    },

    showAlert(value) {
      this.alertValue = value;
    },

    async getRolesPermissionsRes(pRole) {
      let returnValue;
      try {
        let rolesPermissionsReq = apiRolesPermissions.buildRequest(pRole);

        if (rolesPermissionsReq != null) {
          this.SET_ROLES_PERMISSIONS_REQ(rolesPermissionsReq);

          let rolesPermissionsRes = await apiRolesPermissions.getResponse(
            rolesPermissionsReq
          );

          if (VALIDATE_SUCCESS_RES(rolesPermissionsRes)) {
            returnValue = rolesPermissionsRes.data;
            this.showAlert(null);
            this.SET_ROLES_PERMISSIONS_RES(rolesPermissionsRes);
          } else {
            let error = getErrorMessage(rolesPermissionsRes);

            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.showAlert(getErrorAlertValue(error));
            }
          }
        } else {
          throw "invalid request";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ROLES_PERMISSIONS,
          "error in  get Roles Permissions Response",
          err.toString(),
          " getRolesPermissionsRes",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }

      return returnValue;
    },
  },
  computed: {
    ...mapGetters("sRolesPermissions", {
      GET_ROLES_AND_PERMISSIONS_RES: "GET_ROLES_AND_PERMISSIONS_RES",
    }),

    ...mapGetters("sUserRoles", {
      GET_USER_ROLES_RES: "GET_USER_ROLES_RES",
    }),
  },

  beforeCreate() {
    addClassToBody(COMPONENT_NAME.ROLES_PERMISSIONS);
  },

  beforeDestroy() {
    removeClassBody();
  },
};
</script>
