import SERVICE_URL from "../config/API_DATA"
import HttpPost from "../http/httppost.js";
import Logger from './logger.js';
import { FILE_NAME, LOG_TYPE } from "../config/API_DATA";

export default class CreateRole {
    static buildRequest(pRoleObj) {
        let returnValue = null;
        try {


            let body = pRoleObj


            returnValue = body;
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_CREATE_ROLE, "error while building body for CreateRole api", err.toString(), "buildRequest", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }

    static async getResponse(pReqOptions) {


        let returnValue = null;
        try {
            returnValue = await HttpPost.makeHttpPostRequest(SERVICE_URL.CREATE_ROLE, pReqOptions);
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_CREATE_ROLE, "error while getting Response from  CreateRole api ", err.toString(), "getResponse", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }
}