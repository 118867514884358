var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',{staticClass:"grey-header"},[_c('CRow',{staticClass:"flex-flow-column"},[_c('CCol',[_c('strong',[_vm._v(_vm._s(_vm.$t("rolesPermissions.title")))])]),_c('p',{staticClass:"desc"},[_vm._v(_vm._s(_vm.$t("rolesPermissions.desc")))])],1)],1),_c('CCardBody',{staticClass:"pt-3px"},[_c('CCardHeader',{staticClass:"pt-28px"},[_c('CRow',{staticClass:"mob-jc-flex-start ai-center jc-center"},[_c('div',{staticClass:"sub-title-rule"},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("rolesPermissions.select"))+" ")])]),_c('div',{staticClass:"add-role d-flex"},[_c('div',{staticClass:"role-wrapper mb--4px"},[(_vm.isRolesShowState)?_c('cDropDown',{attrs:{"ctrlName":'roleName',"isShowCode":true,"isMultiple":false,"isSearchable":true,"isSystemRole":true,"isB2BRole":true,"isHmsRole":true,"url":_vm.getRolesServiceURL()},on:{"input":_vm.onRoleChange}}):_vm._e()],1),_c('button',{staticClass:"btn action-c-btn pl-20px btn-success add-icon-btn",attrs:{"type":"submit"},on:{"click":_vm.onAddRole}},[_c('img',{staticClass:"height-unset",attrs:{"src":"https://image.flaticon.com/icons/svg/1828/1828925.svg","height":"16px"}})])]),(_vm.isAddRole)?_c('cAddRoleModal',{attrs:{"show":true,"heading":_vm.$t('flightResults.tcHeading'),"buttonList":[
              {
                name: _vm.$t('userStatus.cancel'),
                func: _vm.onCancelRoleModal,
                color: 'danger',
              },
              { name: _vm.$t('userStatus.ok'), color: 'success' } ]},on:{"input":_vm.onRecreateRoles}}):_vm._e()],1)],1),_c('CRow',[(_vm.alertValue)?_c('CAlert',{attrs:{"show":"","color":"danger"}},[(_vm.alertValue && _vm.alertValue.hasOwnProperty('valueList'))?_c('div',_vm._l((_vm.alertValue.valueList),function(value,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(value)+" ")])}),0):_vm._e(),(_vm.alertValue && _vm.alertValue.hasOwnProperty('codeList'))?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t("cAlert.errorCode") + " : "))]),_vm._l((_vm.alertValue.codeList),function(code,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(code + " ")+" ")])})],2):_vm._e(),(_vm.alertValue && _vm.alertValue.constructor == String)?_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.alertValue)+" ")])]):_vm._e()]):_vm._e()],1),_c('cTable',{attrs:{"items":_vm.lsModulesPermissions,"itemsPerPage":_vm.lsModulesPermissions.length,"fields":_vm.lsFields,"hover":"","id":"rules-table","loading":_vm.isLoading},scopedSlots:_vm._u([{key:"title",fn:function(ref){
            var item = ref.item;
return [_c('td',[_c('strong',[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"table-value-desc"},[_vm._v(_vm._s(item.description))])])]}},{key:"no-items-view",fn:function(){return [_c('div',{staticClass:"text-center my-5"},[_c('h2',[_vm._v(_vm._s(_vm.isLoading ? "" : _vm.$t("rolesPermissions.listEmpty")))])])]},proxy:true},{key:"R-header",fn:function(){return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(_vm.$t("rolesPermissions.R"))+" "),_c('cHelpTip',{attrs:{"content":_vm.$t('helpTip.rolesPermissionsRead')}})],1)]},proxy:true},{key:"R",fn:function(ref){
            var item = ref.item;
return [_c('td',[(
                _vm.getPermissionStatus(item.availablepermission, 'R') != null
              )?_c('CSwitch',_vm._b({staticClass:"mx-1",attrs:{"color":"success","checked":_vm.getPermissionStatus(item.availablepermission, 'R'),"variant":"3d","type":"checkbox"},on:{"update:checked":function($event){return _vm.onPermissionUpdate(item, 'R')}}},'CSwitch',_vm.labelIcon,false)):_vm._e()],1)]}},{key:"A-header",fn:function(){return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(_vm.$t("rolesPermissions.A"))+" "),_c('cHelpTip',{attrs:{"content":_vm.$t('helpTip.rolesPermissionsRead')}})],1)]},proxy:true},{key:"A",fn:function(ref){
              var item = ref.item;
return [_c('td',[(
                _vm.getPermissionStatus(item.availablepermission, 'A') != null
              )?_c('CSwitch',_vm._b({staticClass:"mx-1",attrs:{"color":"success","checked":_vm.getPermissionStatus(item.availablepermission, 'A'),"variant":"3d","type":"checkbox"},on:{"update:checked":function($event){return _vm.onPermissionUpdate(item, 'A')}}},'CSwitch',_vm.labelIcon,false)):_vm._e()],1)]}},{key:"W-header",fn:function(){return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(_vm.$t("rolesPermissions.W"))+" "),_c('cHelpTip',{attrs:{"content":_vm.$t('helpTip.rolesPermissionsWrite')}})],1)]},proxy:true},{key:"W",fn:function(ref){
              var item = ref.item;
return [_c('td',[(
                _vm.getPermissionStatus(item.availablepermission, 'W') != null
              )?_c('CSwitch',_vm._b({staticClass:"mx-1",attrs:{"color":"success","checked":_vm.getPermissionStatus(item.availablepermission, 'W'),"variant":"3d","type":"checkbox"},on:{"update:checked":function($event){return _vm.onPermissionUpdate(item, 'W')}}},'CSwitch',_vm.labelIcon,false)):_vm._e()],1)]}},{key:"D-header",fn:function(){return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(_vm.$t("rolesPermissions.D"))+" "),_c('cHelpTip',{attrs:{"content":_vm.$t('helpTip.rolesPermissionsDelete')}})],1)]},proxy:true},{key:"D",fn:function(ref){
              var item = ref.item;
return [_c('td',[(
                _vm.getPermissionStatus(item.availablepermission, 'D') != null
              )?_c('CSwitch',_vm._b({staticClass:"mx-1",attrs:{"color":"success","checked":_vm.getPermissionStatus(item.availablepermission, 'D'),"variant":"3d","type":"checkbox"},on:{"update:checked":function($event){return _vm.onPermissionUpdate(item, 'D')}}},'CSwitch',_vm.labelIcon,false)):_vm._e()],1)]}}])})],1),(_vm.lsModulesPermissions.length > 0)?_c('CCardFooter',{staticClass:"d-flex justify-content-end"},[_c('CRow',[_c('CCol',[_c('CButton',{staticClass:"action-c-btn pl-20px",attrs:{"color":"success","type":"submit"},on:{"click":function($event){return _vm.showModal()}}},[_c('img',{staticClass:"height-unset",attrs:{"src":require("@imgPath/img/svg/icons/save.svg"),"height":"16px"}}),_vm._v(" "+_vm._s(_vm.$t("rolesPermissions.save"))+" ")])],1)],1)],1):_vm._e()],1),(_vm.showAlertModal)?_c('cAlertModal',{key:_vm.modalStatus + 'i',attrs:{"buttonList":_vm.buttonList,"status":_vm.modalStatus,"show":_vm.showAlertModal,"desc":_vm.confirmText}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }