import SERVICE_URL from "../config/API_DATA"
import HttpPost from "../http/httppost.js";
import Logger from './logger.js';
import { FILE_NAME, LOG_TYPE } from "../config/API_DATA";

export default class UserRolesAndPermissions {
    static buildRequest(pRoleName) {
        let returnValue = null;
        try {
            let body = {
                userRole: pRoleName
            }
            returnValue = body;
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_ROLES_PERMISSIONS, "error in building body for UserRoles And Permissions api", err.toString(), "buildRequest", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }

    static async getResponse(pReqOptions) {


        let returnValue = null;
        try {
            returnValue = await HttpPost.makeHttpPostRequest(SERVICE_URL.GET_USER_ROLES_AND_PERMISSIONS, pReqOptions);
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_ROLES_PERMISSIONS, "error in getting Response from  UserRoles And Permissions   api ", err.toString(), "getResponse", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }
}